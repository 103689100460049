import React from 'react'
import times from 'lodash/times'
import styles from './review-stars.scss'
import {FaStar, FaRegStar} from 'react-icons/fa';


class ReviewStars extends React.Component {
  render() {
    const { stars, maxStars, color } = this.props
    const emptyStars = maxStars - stars
    return (
      <div className="review-stars">
        { times(stars, (i) => (
          <FaStar color={color} key={i} />
        )) }
        { times(emptyStars, (i) => (
          <FaRegStar color={color} key={i}/>
        )) }
      </div>
    )
  }
}

ReviewStars.defaultProps = {
  maxStars: 5,
  color: '#FB8E1E'
}

export default ReviewStars
